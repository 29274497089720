.store__logo {
  text-align: center;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  .store__logo-logo {
    max-height: 24px;
  }
}
